import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as math from 'mathjs';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import {
	TextField,
	FormHelperText,
	IconButton,
	Tooltip,
	NativeSelect,
	FormControl
} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import { getElementIcon } from '../utils';

import StyledWrapper from './style';

const operators = ['(', ')', '+', '-', '/', '*'];

export const MUICalculator = React.forwardRef((props, ref) => {
	const { isFormBuilder, disabled } = props;
	const reduxFormAnswers = useSelector((state) => state?.form?.form);
	const [formulaPieces] = useState(props?.data?.calc_formula?.BE || []);
	const [allAnswers, setAllAnswers] = useState([]);
	const [totalValue, setTotalValue] = useState('');

	const getAllAnswers = () => {
		let answers = [];

		reduxFormAnswers?.forEach((answer) => {
			if (answer) {
				answers = [...answers, ...answer];
			}
		});

		setAllAnswers(answers);
	};

	const getValue = (field_name) => {
		const answer = allAnswers?.find((e) => e?.name === field_name);
		return answer?.value !== '' ? answer?.value : undefined;
	};

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);
	const labelStyle = disabled ? { margin: '0px', color: 'rgba(0, 0, 0, 0.38)' } : { margin: '0px' };

	useEffect(() => {
		props.updateStore();
	}, [totalValue]);

	useEffect(() => {
		getAllAnswers();
	}, [reduxFormAnswers]);

	useEffect(() => {
		let formulaString = '';
		if (formulaPieces.length > 0) {
			formulaPieces.forEach((formulaPiece) => {
				const isOperatorPiece = operators.includes(formulaPiece);
				if (isOperatorPiece) {
					const operator = formulaPiece;
					formulaString += operator;
				} else {
					const formInputValue = getValue(formulaPiece?.replace(',', ''));
					formulaString += formInputValue;
				}
			});
			if (formulaString) {
				try {
					const evaluatedFormulaExpression = math.evaluate(formulaString).toLocaleString();
					setTotalValue(evaluatedFormulaExpression);
				} catch (e) {
					setTotalValue('');
				}
			} else {
				setTotalValue('');
			}
		}
	}, [allAnswers]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return isFormBuilder ? (
		<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
			<Tooltip
				arrow
				placement="top"
				title={isOverflowing ? `${props?.data?.label}` : ''}
				enterTouchDelay={0}
			>
				<StyledWrapper.CustomLabel>
					<StyledWrapper.LabelContents ref={labelRef}>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<span>
								{' '}
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</span>
						)}
					</StyledWrapper.LabelContents>
				</StyledWrapper.CustomLabel>
			</Tooltip>
			<TextField
				error={props?.data?.error}
				name={name}
				inputRef={ref}
				disabled={disabled}
				style={{ width: '100%' }}
				multiline
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
			/>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={props?.data?.error}>
					{props?.data?.error
						? props?.data?.error?.error
						: parsedHelperText?.[0]
						? parsedHelperText
						: props?.data?.help_message}
				</FormHelperText>
			</StyledWrapper.FormHelperTextContainer>
		</StyledWrapper.FormControlNoBorder>
	) : (
		<FormControl fullWidth>
			<Tooltip
				arrow
				placement="top"
				title={isOverflowing ? `${props?.data?.label}` : ''}
				enterTouchDelay={0}
			>
				<StyledWrapper.CustomLabel>
					<StyledWrapper.LabelContents ref={labelRef} style={labelStyle}>
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					</StyledWrapper.LabelContents>
				</StyledWrapper.CustomLabel>
			</Tooltip>
			<StyledWrapper.BorderOutline
				disabled={disabled}
				id={`${props?.data?.label}`}
				style={{ minHeight: '56px', marginTop: '16px' }}
			>
				<span style={labelStyle} ref={ref}>
					{totalValue}
				</span>
			</StyledWrapper.BorderOutline>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={props?.data?.error}>
					{props?.data?.error
						? props?.data?.error?.error
						: parsedHelperText?.[0]
						? parsedHelperText
						: props?.data?.help_message}
				</FormHelperText>
			</StyledWrapper.FormHelperTextContainer>
		</FormControl>
	);
});

export const CalculatorLogic = (props) => {
	const [fields, setFields] = useState([]);
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const [formula, setFormula] = useState({
		FE: [],
		BE: []
	});
	const [stringFormula, setStringFormula] = useState('');

	useEffect(() => {
		let allFields = [];
		reduxFormSchema.forEach((e) => {
			allFields.push(...e);
		});
		const onlyNumFields = allFields.filter((e) => e?.key === 'MUI_NumberInput');

		setFields(onlyNumFields);
	}, []);

	const saveFormula = (updatedFormula = formula) => {
		props.update(updatedFormula);
	};

	const showCurrentFormula = () => {
		const reduxFormula = props?.element?.calc_formula;
		return (
			reduxFormula?.FE && (
				<StyledWrapper.CurrentDependanciesContainer>
					<label data-testid={'calculatorLogic-label'}>
						<b>Current Formula:</b>
					</label>
					<StyledWrapper.CurrentDependancies>
						<StyledWrapper.dependancy>{getReadableFormula(reduxFormula)}</StyledWrapper.dependancy>
						<IconButton
							data-testid={'calculatorLogic-delete'}
							aria-label="delete"
							size="small"
							color="error"
							onClick={() => saveFormula(null)}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					</StyledWrapper.CurrentDependancies>
				</StyledWrapper.CurrentDependanciesContainer>
			)
		);
	};

	const findLabel = (fieldName) => {
		const fieldFound = fields?.find((e) => e?.field_name === fieldName);
		return fieldFound.label;
	};

	const getReadableFormula = (reduxFormula = formula) => {
		let readableFormula = '';
		reduxFormula?.FE?.forEach((e) => {
			readableFormula += !operators.includes(e) || e === '(' || e === ')' ? e : ` ${e} `;
		});
		return readableFormula;
	};

	const checkLastEntry = () => {
		const lastEntry = formula?.BE?.[formula?.BE?.length - 1];
		if (lastEntry?.includes('mui_number')) {
			return 'isField';
		}
	};

	useEffect(() => {
		setStringFormula(getReadableFormula());
	}, [formula]);

	return (
		<>
			<StyledWrapper.LogicContainer>
				{checkLastEntry() !== 'isField' ? (
					<FormControl fullWidth>
						<StyledWrapper.CustomLabel
							variant="outlined"
							htmlFor="formulaFieldSelect"
							data-testid={'calculatorLogic-field-label'}
						>
							Select a field
						</StyledWrapper.CustomLabel>
						<StyledWrapper.EditSelectMarginTop>
							<NativeSelect
								inputProps={{
									name: 'numberFields',
									id: 'formulaFieldSelect'
								}}
								data-testid={'calculatorLogic-field-select'}
								onChange={(data) => {
									setFormula({
										FE: [...formula.FE, findLabel(data?.target?.value)],
										BE: [...formula.BE, data?.target?.value]
									});
								}}
							>
								<option value hidden>
									unset
								</option>
								{fields.map((item, index) => {
									if (item.label) {
										return (
											<option
												key={`numberFields-${index}`}
												data-testid={'calculatorLogic-field-option'}
												value={item.field_name}
											>
												{item.label}
											</option>
										);
									}
								})}
							</NativeSelect>
						</StyledWrapper.EditSelectMarginTop>
					</FormControl>
				) : (
					<FormControl fullWidth>
						<StyledWrapper.CustomLabel
							variant="outlined"
							htmlFor="operatorDropdown"
							data-testid={'calculatorLogic-operator-label'}
						>
							Select an Operator
						</StyledWrapper.CustomLabel>
						<StyledWrapper.EditSelectMarginTop>
							<NativeSelect
								data-testid={'calculatorLogic-operator-select'}
								inputProps={{
									name: 'operatorDropdown',
									id: 'operatorDropdown'
								}}
								onChange={(data) => {
									setFormula({
										FE: [...formula.FE, data?.target?.value],
										BE: [...formula.BE, data?.target?.value]
									});
								}}
							>
								<option value hidden>
									unset
								</option>
								{operators.map((item, index) => {
									return (
										<option
											key={`operatorDropdown-items-${index}`}
											data-testid={'calculatorLogic-operator-option'}
											value={item}
										>
											{item}
										</option>
									);
								})}
							</NativeSelect>
						</StyledWrapper.EditSelectMarginTop>
					</FormControl>
				)}

				<StyledWrapper.ButtonsContainer>
					<Tooltip title="Undo" placement="bottom">
						<IconButton
							data-testid={'calculatorLogic-undo'}
							aria-label="removeLastFormula"
							size="small"
							color="error"
							onClick={() => {
								setFormula({
									FE: formula.FE.slice(0, -1),
									BE: formula.BE.slice(0, -1)
								});
							}}
						>
							<UndoIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</StyledWrapper.ButtonsContainer>
			</StyledWrapper.LogicContainer>
			<FormHelperText style={{ marginTop: '-10px' }}>
				{'Pick from available form fields to build your formula. Only Number fields available.'}
			</FormHelperText>
			<StyledWrapper.TextAreaContainer>
				<StyledWrapper.SaveFormulaBox>{stringFormula}</StyledWrapper.SaveFormulaBox>

				<StyledWrapper.ButtonsContainer>
					<Tooltip title="Add Formula" placement="bottom">
						<IconButton
							data-testid={'calculatorLogic-addFormula'}
							aria-label="addFormula"
							size="small"
							color="success"
							onClick={() => formula?.FE?.length > 0 && saveFormula()}
						>
							<DoneIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</StyledWrapper.ButtonsContainer>
			</StyledWrapper.TextAreaContainer>
			{showCurrentFormula()}
		</>
	);
};

MUICalculator.displayName = 'MUI_Calculator';
Registry.register('MUI_Calculator', MUICalculator);
