import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

const CustomLabel = styled(InputLabel)`
	color: ${(props) =>
		props?.disabled ? (props?.value ? 'black!important;' : '') : 'rgba(0, 0, 0, 0.6)!important'};
	margin-bottom: ${(props) => props?.marginBottom || ''};
	margin-top: ${(props) => props?.marginTop || ''};

	& .MuiInputLabel-shrink {
		color: black;
		font-weight: 500 !important;
		-webkit-text-fill-color: rgba(0, 0, 0, 0.6);
	}
`;

const BorderOutline = styled.div`
	display: flex;
	justify-content: ${(props) => (props?.isCentered ? 'center' : '')};
	align-items: ${(props) => (props?.isCentered ? 'center' : '')};
	width: 100%;
	border: ${({ disabled, error }) => {
		if (disabled) return 'none';
		return error ? '1px solid #d32f2f' : '1px solid rgba(0, 0, 0, 0.23)';
	}};
	border-radius: 5px;
	padding: ${(props) => (props?.isFormBuilder ? (props?.src ? '16px 14px' : ' ') : '16px 14px')};
	background: ${(props) => (props?.disabled ? '#f8f8f8' : '')};
	overflow: ${(props) => props?.src && 'scroll'};
	margin-top: 16px;
	& .MuiRating-sizeLarge label {
		margin-bottom: 0px !important;
	}
`;

const CustomDatePicker = styled(DatePicker)`
	& .MuiInputLabel-shrink {
		color: ${(props) => (props?.disabled ? 'black;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) => (props?.disabled ? 'black;' : 'rgba(0, 0, 0, 0.6)')};
	}
`;
const LabelContents = styled.span`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const FormControlNoBorder = styled(FormControl)`
	border: none !important;
`;

const CustomDateTimePicker = styled(DateTimePicker)`
	& label {
		color: ${(props) => (props?.disabled ? 'black!important;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) =>
			props?.disabled ? 'black!important;' : 'rgba(0, 0, 0, 0.6)'};
	}
`;

const CustomTimePicker = styled(TimePicker)`
	& label {
		color: ${(props) => (props?.disabled ? 'black!important;' : '')};
		font-weight: ${(props) => (props?.disabled ? '500!important;' : '')};
		-webkit-text-fill-color: ${(props) =>
			props?.disabled ? 'black!important;' : 'rgba(0, 0, 0, 0.6)'};
	}
`;

const OutlinedInputReadOnly = styled(OutlinedInput)`
	& a {
		color: #007bff;
		-webkit-text-fill-color: #007bff;
	}
`;

const AsteriskSpan = styled.span`
	-webkit-text-fill-color: #e65100;
	color: #e65100;
	font-size: 30px;
	vertical-align: -8px;
`;

const FormHelperTextContainer = styled.div`
	margin-left: 14px;
`;
export default {
	CustomLabel,
	BorderOutline,
	CustomDatePicker,
	CustomDateTimePicker,
	CustomTimePicker,
	OutlinedInputReadOnly,
	AsteriskSpan,
	FormHelperTextContainer,
	LabelContents,
	FormControlNoBorder
};
