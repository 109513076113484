import React, { useState, useEffect, useMemo, useRef } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';
import StyledWrapper from './style';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';

export const MUICheckbox = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;

	const [hasError, setHasError] = useState(props.data?.error || false);
	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	const handleChange = () => {
		setHasError(false);
	};
	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});
	return (
		<>
			<FormControl fullWidth name={name}>
				<Tooltip
					arrow
					placement="top"
					title={isOverflowing ? `${props?.data?.label}` : ''}
					enterTouchDelay={0}
				>
					<StyledWrapper.CustomLabel
						value={defaultValue?.length > 0}
						disabled={disabled ? true : false}
						required={props?.data?.required}
						htmlFor="outlined-adornment-password"
						shrink={true}
					>
						<StyledWrapper.LabelContents ref={labelRef}>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						</StyledWrapper.LabelContents>
						{isFormBuilder && (
							<span>
								{' '}
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</span>
						)}
					</StyledWrapper.CustomLabel>
				</Tooltip>
				{isFormBuilder ? (
					<StyledWrapper.BorderOutline
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<FormGroup>
							<div
								style={{
									display: 'flex',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const checkboxProps = {};
									checkboxProps.name = `option_${option.key}`;

									checkboxProps.type = 'checkbox';
									checkboxProps.value = option.key;

									checkboxProps.defaultChecked =
										props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;

									return (
										<div key="Checkbox">
											<FormControlLabel
												error={hasError}
												className="disableWhiteBackground noMarginTop"
												disabled={props?.disabled}
												onChange={handleChange}
												control={
													<Checkbox
														id={`fid_${this_key}`}
														{...checkboxProps}
														disabled={props?.disabled}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</FormGroup>
					</StyledWrapper.BorderOutline>
				) : (
					<StyledWrapper.BorderOutlineMarginTop
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<FormGroup>
							<div
								style={{
									display: 'flex',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const checkboxProps = {};
									checkboxProps.name = `option_${option.key}`;

									checkboxProps.type = 'checkbox';
									checkboxProps.value = option.key;

									checkboxProps.defaultChecked =
										props.defaultValue !== undefined && props.defaultValue.indexOf(option.key) > -1;

									return (
										<div key="Checkbox">
											<FormControlLabel
												error={hasError}
												className="disableWhiteBackground noMarginTop"
												disabled={props?.disabled}
												onChange={handleChange}
												control={
													<Checkbox
														id={`fid_${this_key}`}
														{...checkboxProps}
														disabled={props?.disabled}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</FormGroup>
					</StyledWrapper.BorderOutlineMarginTop>
				)}
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUICheckbox.displayName = 'MUI_Checkbox';
Registry.register('MUI_Checkbox', MUICheckbox);
