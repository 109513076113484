import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';

export const MUIRadioButton = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;

	const [hasError, setHasError] = useState(props?.data?.error || false);
	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const handleChange = () => {
		setHasError(false);
	};

	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<>
			<FormControl fullWidth name={name}>
				<Tooltip
					arrow
					placement="top"
					title={isOverflowing ? `${props?.data?.label}` : ''}
					enterTouchDelay={0}
				>
					<StyledWrapper.CustomLabel
						error={hasError}
						value={defaultValue?.length > 0}
						disabled={disabled ? true : false}
						isOverflowing={isOverflowing}
						required={props?.data?.required}
						htmlFor="outlined-adornment-password"
						shrink={true}
					>
						<StyledWrapper.LabelContents ref={labelRef}>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
							{isFormBuilder && (
								<span>
									{' '}
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</span>
							)}
						</StyledWrapper.LabelContents>
					</StyledWrapper.CustomLabel>
				</Tooltip>
				{isFormBuilder ? (
					<StyledWrapper.BorderOutline
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={defaultValue}
							name="radio-buttons-group"
							error={hasError}
						>
							<div
								style={{
									display: 'flex',
									margin: '0px',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const radioButtonProps = {};
									radioButtonProps.name = `option_${option.key}`;

									radioButtonProps.type = 'radio';
									radioButtonProps.value = option.key;
									radioButtonProps.inputProps = { actualValue: option?.text };
									if (props.mutable) {
										radioButtonProps.defaultChecked =
											props.defaultValue !== undefined &&
											props.defaultValue.indexOf(option.key) > -1;
									}
									if (props.read_only) {
										radioButtonProps.disabled = true;
									}

									return (
										<div key="RadioGroup">
											<FormControlLabel
												className="disableWhiteBackground noMarginTop"
												style={{ marginBottom: '0px' }}
												disabled={radioButtonProps.disabled || disabled}
												onChange={handleChange}
												control={
													<Radio
														id={`fid_${this_key}`}
														{...radioButtonProps}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</RadioGroup>
					</StyledWrapper.BorderOutline>
				) : (
					<StyledWrapper.BorderOutlineMarginTop
						error={hasError}
						className={'customInputOutline'}
						disabled={disabled ? true : false}
					>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={defaultValue}
							name="radio-buttons-group"
							error={hasError}
						>
							<div
								style={{
									display: 'flex',
									margin: '0px',
									flexDirection: `${props?.data?.inline ? 'row' : 'column'}`
								}}
							>
								{props?.data?.options.map((option) => {
									const this_key = `preview_${option.key}`;
									const radioButtonProps = {};
									radioButtonProps.name = `option_${option.key}`;

									radioButtonProps.type = 'radio';
									radioButtonProps.value = option.key;
									radioButtonProps.inputProps = { actualValue: option?.text };
									if (props.mutable) {
										radioButtonProps.defaultChecked =
											props.defaultValue !== undefined &&
											props.defaultValue.indexOf(option.key) > -1;
									}
									if (props.read_only) {
										radioButtonProps.disabled = true;
									}

									return (
										<div key="RadioGroup">
											<FormControlLabel
												className="disableWhiteBackground noMarginTop"
												style={{ marginBottom: '0px' }}
												disabled={radioButtonProps.disabled || disabled}
												onChange={handleChange}
												control={
													<Radio
														id={`fid_${this_key}`}
														{...radioButtonProps}
														inputProps={{
															'data-value': option?.value
														}}
													/>
												}
												label={option?.text}
											/>
										</div>
									);
								})}
							</div>
						</RadioGroup>
					</StyledWrapper.BorderOutlineMarginTop>
				)}
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});
MUIRadioButton.displayName = 'MUI_RadioButton';
Registry.register('MUI_RadioButton', MUIRadioButton);
