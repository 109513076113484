import React, { useRef, useState, useEffect } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';

import StyledWrapper from './style';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';

export const MUITags = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;

	const [tags, setTags] = React.useState(defaultValue || []);
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const handleChange = (event) => {
		if (event) {
			const {
				target: { value }
			} = event;

			const chosenValue = typeof value === 'string' ? value.split(',') : value;
			setTags(chosenValue);
		}
		setHasError(false);
	};

	const getChipText = (value) => {
		if (value) {
			const allOptions = props?.data?.options;
			const correctItem = allOptions?.find((e) => e?.key === value);
			return correctItem?.text;
		}
		return '';
	};

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<>
			<FormControl fullWidth>
				<Tooltip
					arrow
					placement="top"
					title={isOverflowing ? `${props?.data?.label}` : ''}
					enterTouchDelay={0}
				>
					<StyledWrapper.CustomLabel
						disabled={defaultValue && disabled}
						required={props?.data?.required}
						shrink={isFormBuilder || undefined}
						value={parsedLabel?.[0] ? parsedLabel : props?.data?.label}
					>
						<StyledWrapper.LabelContents ref={labelRef}>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{props?.data?.label}
							{isFormBuilder && (
								<span>
									{' '}
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</span>
							)}
						</StyledWrapper.LabelContents>
					</StyledWrapper.CustomLabel>
				</Tooltip>
				<Select
					sx={isFormBuilder ? {} : { marginTop: '16px' }}
					error={hasError}
					inputProps={{ ref: ref }}
					id={`DropdownID_${props?.field_name}`}
					disabled={disabled}
					defaultValue={defaultValue}
					value={tags}
					onChange={handleChange}
					name={name}
					multiple
					renderValue={(selected) => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected.map((value) => {
								const textValue = getChipText(value);
								return <Chip key={value} label={textValue} />;
							})}
						</Box>
					)}
				>
					{props?.data?.options?.map((e) => {
						return (
							<MenuItem key={e?.key} value={e?.key}>
								{e?.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUITags.displayName = 'MUI_Tags';
Registry.register('MUI_Tags', MUITags);
