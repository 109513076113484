import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon } from '../utils';
import StyledWrapper from './style';

import FormHelperText from '@mui/material/FormHelperText';
import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';

export const MUIRating = React.forwardRef((props, ref) => {
	const { defaultValue, disabled, isFormBuilder, name } = props;
	const [value, setValue] = useState(defaultValue);

	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<>
			<FormControl fullWidth>
				<Tooltip
					arrow
					placement="top"
					title={isOverflowing ? `${props?.data?.label}` : ''}
					enterTouchDelay={0}
				>
					<StyledWrapper.CustomLabel
						error={hasError}
						value={defaultValue}
						disabled={disabled ? true : false}
						required={props?.data?.required}
						htmlFor="outlined-adornment-password"
						shrink={true}
					>
						<StyledWrapper.LabelContents ref={labelRef}>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
							{isFormBuilder && (
								<span>
									{' '}
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</span>
							)}
						</StyledWrapper.LabelContents>
					</StyledWrapper.CustomLabel>
				</Tooltip>
				<StyledWrapper.BorderOutline
					error={hasError}
					className={'customInputOutline paddingTop30'}
					disabled={disabled ? true : false}
				>
					<Rating
						name={name}
						error={hasError}
						required={props?.data?.required}
						ref={ref}
						onChange={(e, newValue) => {
							setValue(newValue);
							setHasError(false);
						}}
						defaultValue={value}
						value={value}
						size="large"
						disabled={disabled}
						data-value={value}
					/>
				</StyledWrapper.BorderOutline>
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{' '}
					{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUIRating.displayName = 'MUI_Rating';
Registry.register('MUI_Rating', MUIRating);
