// Application Statuses
export const COMPLETED = 'completed';
export const DENIED = 'denied';
export const ON_HOLD = 'on hold';
export const PENDING_DECISION = 'pending decision';
export const REC_AWARD = 'rec. award';
export const FULL_REVIEW = 'full review';
export const CLOSED = 'closed';

export type ApplicationStatus =
	| typeof COMPLETED
	| typeof DENIED
	| typeof ON_HOLD
	| typeof PENDING_DECISION
	| typeof REC_AWARD
	| typeof FULL_REVIEW
	| typeof CLOSED;

// Input Placeholders
export const ONLY_DATE = 'mm/dd/yyyy';

// Support Email
export const SUPPORT_EMAIL = 'support.ecivis@eunasolutions.com';
