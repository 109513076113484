import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { getElementIcon } from '../utils';
import StyledWrapper from './style';
import Tooltip from '@mui/material/Tooltip';

export const MUIPhoneNumber = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, isFormBuilder } = props;
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const handleChange = () => {
		setHasError(false);
	};
	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }}>
			<Tooltip
				arrow
				placement="top"
				title={isOverflowing ? `${props?.data?.label}` : ''}
				enterTouchDelay={0}
			>
				<StyledWrapper.CustomLabel disabled={disabled}>
					<StyledWrapper.LabelContents ref={labelRef}>
						{isFormBuilder && getElementIcon(props.data?.key)}
						{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
						{isFormBuilder && (
							<span>
								{' '}
								{props.data?.unique_identifier
									? `(ff-${props.data?.unique_identifier})`
									: `(tmp-${props.data?.temp_order})`}
							</span>
						)}
					</StyledWrapper.LabelContents>
					{props?.data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
				</StyledWrapper.CustomLabel>
			</Tooltip>

			<TextField
				error={hasError}
				required={props?.data?.required}
				id="outlined-error-helper-text"
				name={name}
				inputRef={ref}
				disabled={disabled}
				defaultValue={defaultValue}
				style={styleForNotFormBuilder}
				type="string"
				InputLabelProps={{ shrink: isFormBuilder || undefined }}
				onChange={handleChange}
			/>
			<FormHelperText error={hasError}>
				{' '}
				{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
			</FormHelperText>
			{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
		</StyledWrapper.FormControlNoBorder>
	);
});
MUIPhoneNumber.displayName = 'MUI_PhoneNumber';
Registry.register('MUI_PhoneNumber', MUIPhoneNumber);
