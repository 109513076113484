import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { getOldBlueColor } from '../../utils/utilFunctions';
import {
	CardRowTitleProps,
	CopyButtonProps,
	GroupTextAndIconProps,
	CardRowInfoButtonProps,
	CardRowInfoLinkProps,
	CardRowContainerParentProps,
	CellContainerProps,
	CardRowInfoProps,
	CardRowMultiLinksProps,
	NotificationCellContainerProps,
	NotificationControlProps,
	TemplateRowContainerProps,
	TemplateRowIdProps,
	CenteredTextProps,
	BlockIndicatorProps,
	TitleAndIdCardRowContainerProps
} from './styleTypes';

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-items: center;
	gap: 26px;
	margin-top: 30px;
	@media (max-width: 768px) {
		justify-content: flex-start;
		width: fit-content;
	}
`;

const OverviewRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 26px;
	width: 100%;
	@media (max-width: 1400px) {
		flex-direction: column;
		width: 100%;
	}
`;

const FullContainer = styled.div`
	width: 100%;
	margin: 30px 0px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;

const HalfContainer = styled.div`
	width: 50%;
	min-width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const CardTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0px 0px 14px 0px;
`;

const CardTitle = styled(Link)`
	font-weight: 500;
`;

const CardTitleNoLink = styled.p`
	font-weight: 500;
	margin: 0px;
`;

const CopyButton = styled(IconButton)<CopyButtonProps>`
	position: ${(props) => props?.position || 'absolute !important;'};
	right: -25px;
`;

const CardRowContainerParent = styled.div<CardRowContainerParentProps>`
	position: relative;
	display: flex;
	align-items: center;
	gap: 40px;
	width: ${(props) => props?.width || '100%'};
	height: ${(props) => (props?.dropdownstate ? 'fit-content' : '52px')};

	.label-help-icon {
		visibility: hidden;
	}

	&:hover .label-help-icon {
		visibility: initial !important;
`;

const CardRowContainerLeft = styled(CardRowContainerParent)`
	justify-content: left;
`;

const CardRowContainer = styled(CardRowContainerParent)`
	justify-content: center;
`;

const CardRowTitle = styled.h6<CardRowTitleProps>`
	margin: ${(props) => props?.margin || '0px 0px'};
	position: relative;
	width: ${(props) => props?.width || '33%'};
	text-align: end;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
`;

const GroupTextAndIcon = styled.span<GroupTextAndIconProps>`
	position: relative;
	text-align: end;
	display: flex;
	align-items: center;
	gap: 5px;
	width: ${(props) => props?.width};
`;

const CardRowInfo = styled.h6<CardRowInfoProps>`
	display: ${(props) => props?.display || 'block'};
	align-items: center;
	justify-content: ${(props) => props?.justifyContent || 'space-between'};
	gap: 20px;
	text-align: ${(props) => props?.align || 'start'};
	color: ${(props) => (props?.isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'black')};
	pointer-events: ${(props) => props?.status && 'none'};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 0px;
`;

const CardRowDataSet = styled.h6`
	width: 55%;
	color: black;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: flex-end;
	gap: 5px;
`;

const HideDropdownTrigger = styled.div`
	& .MuiDataGrid-treeDataGroupingCellToggle {
		display: none !important;
	}
`;

const CellContainer = styled.div<CellContainerProps>`
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
	max-width: fit-content;
	margin: ${(props) => props?.margin};
	margin-left: ${(props) => props?.marginLeft};
	color: ${(props) => props?.status && 'rgba(0, 0, 0, 0.38)'};
	pointer-events: ${(props) => props?.status && 'none'};
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CardRowInfoLink = styled(Link)<CardRowInfoLinkProps>`
	display: block;
	align-items: center;
	gap: 10px;
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
	color: ${(props) => (props?.isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'black')};
	overflow: hidden;
	font-size: 14px;
	margin: auto 0px;
	line-height: normal;
	cursor: ${(props) => props?.cursor};
`;

const CardRowSubText = styled.h6`
	font-size: 14px;
`;

const CardRowInfoButton = styled(Button)<CardRowInfoButtonProps>`
	width: ${(props) => props?.width || '100%'}!important;
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center;
	gap: 6px;
	margin-left: ${(props) => props?.marginLeft}!important;
	padding: 0px 15px !important;
	& p {
		color: ${(props) => (props?.disabled ? 'grey' : `${getOldBlueColor()}`)};
		margin: 0px;
		text-align: start;
		width: 100%;
		text-overflow: ellipsis;
		max-width: 33ch;
		white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
		overflow: hidden;
		font-size: 14px;
		text-transform: lowercase;
	}
`;

const AssignmentContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 30px;
	margin-left: 15px;
`;

const CardRowMultiLinks = styled.div<CardRowMultiLinksProps>`
	width: ${(props) => props?.width || '55%'};
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: ${(props) => (props?.dropdownstate ? 'normal' : 'nowrap')};
	overflow: hidden;
	font-size: 14px;
`;

const ChartContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 85%;
`;

const AllNotificationTitle = styled.div`
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
`;

const UnexpandedText = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 30vw;
	min-width: 400px;
`;

const NotificationCellContainer = styled.div<NotificationCellContainerProps>`
	margin-bottom: ${(props) => (props?.isOpen ? 'auto' : '0px')};
	display: flex;
	padding: 10px 0px;
	height: fit-content;
	align-items: ${(props) => props?.alignItem || 'center'};
	min-height: 60px;
	width: 100%;
`;

const ActionsContainerOutermost = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	gap: 10px;

	@media (max-width: 768px) {
		flex-wrap: wrap;
	}
`;

const Toolbar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const ToolbarActionTitle = styled.p`
	font-size: 12px;
	font-weight: 400;
	margin: 0px;
`;

const NotificationsControlsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	margin-bottom: 20px;
	margin-right: 20px;
	margin-top: 20px;
`;

const NotificationsActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	margin-bottom: 20px;
`;

const NotificationControl = styled(Link)<NotificationControlProps>`
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 12px;
	font-weight: 600;
	pointer-events: ${(props) => (props?.isDisabled ? 'none' : 'initial')};
	cursor: ${(props) => (props?.isDisabled ? 'initial' : 'pointer')};
	opacity: ${(props) => (props?.isDisabled ? '0.5' : '1')};
	color: ${(props) => props?.isGray && 'rgba(0, 0, 0, 0.6)'};
	&:hover,
	&:hover path {
		text-decoration: ${(props) => (props?.isDisabled ? 'none' : 'underline')};
		color: ${(props) => props?.isGray && `${getOldBlueColor()}`};
		fill: #1976d2 !important;
	}
`;

const NotificationControlDivider = styled.div`
	width: 1px;
	height: 14px;
	background: #e0e0e0;
`;

const Hint = styled.p`
	margin: 0px;
	position: absolute;
	bottom: 35px;
	right: 15px;
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 9px;
	color: rgba(0, 0, 0, 0.6);
`;

//TABLE CONTAINERS
const TableContainer = styled.div``;

const BlockIndicator = styled.div<BlockIndicatorProps>`
	position: absolute;
	left: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Roboto, sans-serif;
	color: #b0bec5;
	font-size: 17px;
	margin-top: 0px;
	height: 39px;
	justify-content: center;
	align-content: center;

	&::after {
		content: ${(props) => (props?.isSubmission ? '' : ' ')};
		background: #b0bec5;
		width: 1px;
		height: 30px;
	}
`;

const BlockVerticalLine = styled.div`
	position: absolute;
	display: flex;
	left: 0px;
	height: 53px;
	width: 24px;
	background-color: white;
	margin-top: -11px;
	justify-content: center;

	&::after {
		content: ' ';
		background: #b0bec5;
		position: relative;
		width: 2px;
		height: 100%;
	}
`;

// GENERAL
const CenteredText = styled.div<CenteredTextProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	cursor: ${(props) => (props?.isLink ? 'pointer' : 'initial')};
`;

const NothingToShow = styled.h6`
	text-align: center;
`;

const ColumnFormat = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const TemplateRowContainer = styled.div<TemplateRowContainerProps>`
	width: ${(props) => props?.width || '55%'};
	display: flex;
	align-items: center;
	color: black;
	cursor: pointer;
	max-width: 40ch;
	white-space: nowrap;
	gap: 4px;
	text-align: ${(props) => props?.centerText && 'center!important;'};
	// margin: 14px 0px;
	color: rgba(25, 118, 210, 1);
	}
`;

const TemplateRowTitle = styled(Typography)`
	text-decoration-color: rgba(25, 118, 210, 0.4) !important;
	color: rgba(25, 118, 210, 1);
	font-size: 14px;
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;

	:hover {
		text-decoration: underline;
	}
`;

const TemplateRowId = styled.span<TemplateRowIdProps>`
	color : ${(props) => (props.status ? 'rgba(0, 0, 0, 0.6)!important' : 'rgba(0, 0, 0, 0.38)')};
}
`;

const TitleAndIdCardRowContainer = styled.div<TitleAndIdCardRowContainerProps>`
	width: ${(props) => props?.width || '55%'};
	display: flex;
	align-items: center;
	color: black;
	max-width: 60ch;
	white-space: nowrap;
	gap: 4px;
	text-align: ${(props) => props?.centerText && 'center!important;'};
	}
`;

const TitleAndIdCardRowTitle = styled(Typography)`
	font-size: 14px;
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const TitleAndIdCardRowId = styled.span`
	color: rgba(0, 0, 0, 0.6) !important;
`;

export default {
	OverviewContainer,
	OverviewRow,
	HideDropdownTrigger,
	CardTitleContainer,
	CardTitle,
	CardTitleNoLink,
	FullContainer,
	HalfContainer,
	CardRowContainerParent,
	CardRowContainer,
	CardRowTitle,
	GroupTextAndIcon,
	CardRowInfo,
	CardRowDataSet,
	CellContainer,
	CardRowInfoLink,
	CardRowSubText,
	CardRowInfoButton,
	AssignmentContainer,
	CardRowMultiLinks,
	CopyButton,
	ChartContainer,
	Hint,
	AllNotificationTitle,
	Toolbar,
	ActionsContainerOutermost,
	ToolbarActionTitle,
	NotificationsControlsContainer,
	NotificationsActionsContainer,
	NotificationControl,
	NotificationControlDivider,
	UnexpandedText,
	NotificationCellContainer,
	ColumnFormat,
	CenteredText,
	NothingToShow,
	TableContainer,
	BlockIndicator,
	BlockVerticalLine,
	TemplateRowContainer,
	TemplateRowTitle,
	TemplateRowId,
	CardRowContainerLeft,
	TitleAndIdCardRowContainer,
	TitleAndIdCardRowId,
	TitleAndIdCardRowTitle
};
