import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { setFormMetaInfo, setNewForm, setToReviewForms } from '../FormBuilderLibrary/slice';

import StyledWrapper from './style';
import { RTE_SETTINGS } from './variables';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyTextField } from './customComponents/MUI_TextField';
import { useGetIndividualFormsQuery } from '../../services/endpoints/formBuilderEndpoints';
import { getEntityPrefix, isEmptyRTE } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import { useApplicationSetup } from '../../hooks/useApplicationSetup';

export const FormInfo = ({
	formId,
	onlyInstructions,
	staticInstructions,
	preSave,
	isMasterLayout,
	in_use,
	setMetaInfoChanged,
	isTemplate
}) => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const stageID = searchParams.get('stageId');

	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const programInfo = useSelector((state) => state.globalProgramVariables.program);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);
	const newForm = useSelector((state) => state.form.new_form);

	// API CALL

	const { data: setupRows } = useApplicationSetup();

	const [skip, setSkip] = useState(true);
	const {
		data: individualFormData,
		refetch: refetchForm,
		isUninitialized
	} = useGetIndividualFormsQuery(
		{
			apiToken,
			id: formId
		},
		{ skip }
	);

	const [expanded, setExpanded] = useState('panel1');
	const [readOnly, setReadOnly] = useState(isMasterLayout ? true : isReadOnly);
	const [isLoading, setIsLoading] = useState(false);
	const [initialData, setInitialData] = useState({});
	const [finalData, setFinalData] = useState({
		program: {
			programId: programID
		},
		label: '',
		form_type: searchParams?.get('formBuilder')
	});
	const [instructionData, setInstructionData] = useState('');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleDataChange = (inputName, value) => {
		setMetaInfoChanged && value && setMetaInfoChanged(true);
		setFinalData({
			...finalData,
			[inputName]: value
		});
	};

	const hasFieldChanged = (fieldName) => {
		return initialData[fieldName] !== finalData[fieldName];
	};

	const saveMetaInfo = (event) => {
		event?.preventDefault();
		dispatch(setFormMetaInfo(finalData));
		const currentStage = setupRows?.find((e) => e?.appId === stageID);

		(currentStage || individualFormData) &&
			dispatch(
				setToReviewForms(currentStage?.review_info || individualFormData?.stage?.review_info)
			);
		setTimeout(function () {
			setIsLoading(false);
		}, 500);
	};

	const instructionDataHandler = (content, delta, source, editor) => {
		if (source === 'user') {
			setInstructionData(content);
			handleDataChange('instructions', content);
		}
	};

	useEffect(() => {
		setReadOnly((isMasterLayout || in_use) && !isTemplate ? true : isReadOnly);
	}, [formMetaInfo, isReadOnly]);

	useEffect(() => {
		const id = formId === 'undefined' ? null : formId;
		setSkip(!id);
		if (!isUninitialized) refetchForm();
	}, [formId]);

	useEffect(() => {
		const newId = newForm === '' ? null : newForm;
		if (newId && formId === 'undefined') {
			dispatch(setNewForm(''));
		}
	}, [newForm]);

	useEffect(() => {
		setIsLoading(true);
		const apiData = programInfo && {
			...formMetaInfo,
			program: {
				programId: programID
			},
			form_type: searchParams?.get('formBuilder'),
			project_title: programInfo?.title,
			stage: individualFormData?.stage || searchParams.get('stageId')
		};

		const formattedFormData = {
			...individualFormData,
			form_id:
				individualFormData?.unique_identifier &&
				`${getEntityPrefix({
					type:
						individualFormData?.form_type === 'application' ? 'application-form' : 'review-form',
					isMaters: !isTemplate,
					isBaseTemplate: isTemplate,
					isAdHoc: individualFormData?.is_adhoc
				})}${individualFormData?.unique_identifier}`,
			program: individualFormData?.program,
			project_title: setupRows?.[0]?.title || programInfo?.title,
			stage: individualFormData?.stage || searchParams.get('stageId')
		};

		const inputData = preSave && {
			...preSave.formMetaInfo
		};

		if (individualFormData || apiData) {
			setInitialData(individualFormData ? formattedFormData : apiData);

			if (preSave) {
				setFinalData(inputData);
			} else {
				setFinalData(individualFormData ? formattedFormData : apiData);
			}
		}
	}, [preSave, programInfo, individualFormData]);

	useEffect(() => {
		saveMetaInfo();

		if (isEmptyRTE(finalData.instructions)) {
			setInstructionData('');
		} else {
			setInstructionData(finalData.instructions);
		}
	}, [finalData]);

	return (
		<div className="formInfo-container">
			{isLoading ? (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						margin: '30px 0px',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<StyledWrapper.Accordion
					disableGutters
					elevation={0}
					square
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<StyledWrapper.AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="form-meta-header-content"
						id="form-meta-header"
					>
						FORM META SETTINGS & INFO
					</StyledWrapper.AccordionSummary>
					<StyledWrapper.AccordionDetails>
						<StyledWrapper.FormInfoOutermost form={'formMetaInfo'}>
							{!onlyInstructions && (
								<>
									<StyledWrapper.FormInfoRow>
										<MyTextField
											name={'Form Name'}
											data={{
												label: 'Form Name',
												required: true
											}}
											disabled={readOnly}
											sx={{ width: '80%' }}
											className={hasFieldChanged('label') ? 'isChanged' : ''}
											id="formBuilder-meta-input-title"
											defaultValue={finalData?.label}
											onChange={(event) => {
												handleDataChange('label', event.target.value);
											}}
											onBlur={saveMetaInfo}
										/>
										<MyTextField
											name={'Form ID'}
											data={{
												label: 'Form ID',
												required: true
											}}
											disabled
											sx={{ width: '20%' }}
											id="formBuilder-meta-input-id"
											defaultValue={finalData?.form_id || formMetaInfo?.form_id || '[]'}
											onChange={(event) => {
												handleDataChange('id', event.target.value);
											}}
											onBlur={saveMetaInfo}
										/>
									</StyledWrapper.FormInfoRow>

									{isTemplate ? (
										<StyledWrapper.FormInfoRow>
											<MyTextField
												name={'Template Description'}
												data={{
													label: 'Template Description'
												}}
												disabled={readOnly}
												className={hasFieldChanged('label') ? 'isChanged' : ''}
												id="formBuilder-meta-input-description"
												defaultValue={finalData?.description}
												onChange={(event) => {
													handleDataChange('description', event.target.value);
												}}
												onBlur={saveMetaInfo}
											/>
										</StyledWrapper.FormInfoRow>
									) : (
										<StyledWrapper.FormInfoRow>
											<MyTextField
												name={'Program Name'}
												data={{
													label: 'Program Name'
												}}
												disabled
												sx={{ width: '50%' }}
												className={hasFieldChanged('label') ? 'isChanged' : ''}
												id="formBuilder-meta-input-appTitle"
												defaultValue={
													finalData?.program?.project_title ||
													finalData?.project_title ||
													programInfo?.title
												}
												onChange={(event) => {
													handleDataChange('project_title', event.target.value);
												}}
												onBlur={saveMetaInfo}
											/>
											<MyTextField
												name={'Program Name'}
												data={{
													label: `${
														searchParams?.get('formBuilder') === 'application'
															? 'Submission Stage'
															: 'Evaluation Stage'
													}`
												}}
												disabled
												sx={{ width: '50%' }}
												className={hasFieldChanged('label') ? 'isChanged' : ''}
												id="formBuilder-meta-input-stageTitle"
												defaultValue={
													finalData?.stage_title ||
													finalData?.stage?.stage_title ||
													finalData?.stage?.stage_name ||
													finalData?.stage
												}
												onChange={(event) => {
													handleDataChange('stageTitle', event.target.value);
												}}
												onBlur={saveMetaInfo}
											/>
										</StyledWrapper.FormInfoRow>
									)}
								</>
							)}
							<button
								type="submit"
								className="meta-info-form"
								style={{ display: 'none' }}
								onClick={saveMetaInfo}
							/>
							<div>
								<label className={'gl-label'}>
									Form Instructions{' '}
									<span aria-hidden="true" className="MuiFormLabel-asterisk">
										*
									</span>
								</label>
								<ReactQuill
									readOnly={readOnly}
									value={instructionData}
									onChange={instructionDataHandler}
									theme={RTE_SETTINGS.theme}
									modules={RTE_SETTINGS.modules}
									formats={RTE_SETTINGS.formats}
								/>
							</div>
						</StyledWrapper.FormInfoOutermost>
					</StyledWrapper.AccordionDetails>
				</StyledWrapper.Accordion>
			)}
		</div>
	);
};
