import React, { forwardRef, useState, useEffect, useMemo, useRef } from 'react';

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { InputAdornment } from '@mui/material';

import StyledWrapper from './style';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';
import { getElementIcon } from '../utils';
import Tooltip from '@mui/material/Tooltip';

export const MUINumberInput = forwardRef((props, ref) => {
	const { name, defaultValue, disabled, data, isFormBuilder } = props;

	const [value, setValue] = useState(defaultValue);
	const [refresh, setRefresh] = useState(defaultValue);
	const [hasError, setHasError] = useState(props?.data?.error || false);

	function formatChangeEvent(number) {
		let value = number;
		value = value.replace(/[^0-9.]/g, '');
		if (value) {
			if (value[0] == 0 || value[0] == '.') {
				value = '';
			}
			if (value.includes('.')) {
				value = value.slice(0, 15);
				value = value.split('.');
				let numberPart = value.shift();
				let fractionalPart = value.join('');
				if (fractionalPart.length > 2) {
					fractionalPart = fractionalPart.slice(0, 2);
				}
				value = numberPart + '.' + fractionalPart;
				value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
			} else {
				value = value.slice(0, 12);
			}
		}
		return value;
	}

	function formatBlurEvent(number, isCurrency) {
		if (number && isCurrency) {
			if (number.includes('.')) {
				const parts = number.split('.');
				if (parts[1].length == 1) {
					number = number + '0';
				} else if (parts[1].length == 0) {
					number = number + '00';
				}
			} else {
				number = number + '.00';
			}
			number = number.replace(/(?=(\d{3})+(\D))\B/g, ',');
		}

		return number;
	}

	const handleChange = (event) => {
		const number = event.target.value;
		setValue(formatChangeEvent(number));
		setHasError(false);
	};

	const handleBlur = (event) => {
		let number = event.target.value;
		setValue(formatBlurEvent(number, true));
	};

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);

	const styleForNotFormBuilder = !isFormBuilder
		? { width: '100%', marginTop: '16px' }
		: { width: '100%' };
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		setRefresh(!refresh);
	}, [props]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});
	return (
		<>
			{!disabled ? (
				props?.data?.description === 'currency' ? (
					<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
						<Tooltip
							arrow
							placement="top"
							title={isOverflowing ? `${props?.data?.label}` : ''}
							enterTouchDelay={0}
						>
							<StyledWrapper.CustomLabel>
								<StyledWrapper.LabelContents ref={labelRef}>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<span>
											{' '}
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</span>
									)}
								</StyledWrapper.LabelContents>
								{props?.data?.required && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</StyledWrapper.CustomLabel>
						</Tooltip>
						<TextField
							error={hasError}
							required={props?.data?.required}
							id="outlined-error-helper-text"
							name={name}
							inputRef={ref}
							disabled={disabled}
							value={value}
							type="string"
							onBlur={handleBlur}
							onChange={handleChange}
							style={styleForNotFormBuilder}
							InputProps={{
								inputComponent: 'input',
								inputProps: {
									maxLength: 18
								},
								startAdornment: <InputAdornment position="start">$</InputAdornment>
							}}
						/>
						<FormHelperText error={hasError}>
							{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
						</FormHelperText>
						{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
					</StyledWrapper.FormControlNoBorder>
				) : (
					<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
						<Tooltip
							arrow
							placement="top"
							title={isOverflowing ? `${props?.data?.label}` : ''}
							enterTouchDelay={0}
						>
							<StyledWrapper.CustomLabel>
								<StyledWrapper.LabelContents ref={labelRef}>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<span>
											{' '}
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</span>
									)}
								</StyledWrapper.LabelContents>
								{props?.data?.required && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</StyledWrapper.CustomLabel>
						</Tooltip>
						<TextField
							error={hasError}
							required={props?.data?.required}
							id="outlined-error-helper-text"
							name={name}
							inputRef={ref}
							disabled={disabled}
							defaultValue={defaultValue}
							onChange={handleChange}
							InputLabelProps={{ shrink: isFormBuilder || undefined }}
							style={styleForNotFormBuilder}
							type="number"
						/>
						<FormHelperText error={hasError}>
							{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
						</FormHelperText>
						{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
					</StyledWrapper.FormControlNoBorder>
				)
			) : (
				<StyledWrapper.FormControlNoBorder sx={{ width: '100%' }} variant="outlined">
					<Tooltip
						arrow
						placement="top"
						title={isOverflowing ? `${props?.data?.label}` : ''}
						enterTouchDelay={0}
					>
						<StyledWrapper.CustomLabel
							value={defaultValue}
							disabled={disabled}
							//Having required on customLabel will have double asterisks show up
							htmlFor="outlined-adornment-password"
						>
							<StyledWrapper.LabelContents ref={labelRef}>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<span>
										{' '}
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</span>
								)}
							</StyledWrapper.LabelContents>
							{props?.data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
						</StyledWrapper.CustomLabel>
					</Tooltip>
					<StyledWrapper.OutlinedInputReadOnly
						id="outlined-adornment-password"
						type={'text'}
						disabled={disabled}
						style={{ width: '100%', border: '0px', marginTop: '16px' }}
						inputComponent={() => {
							return (
								<StyledWrapper.RemoveBorderContainer>
									<TextField
										error={hasError}
										required={props?.data?.required}
										id="outlined-error-helper-text"
										name={name}
										inputRef={ref}
										onChange={handleChange}
										disabled={disabled}
										defaultValue={formatBlurEvent(
											defaultValue,
											props?.data?.description === 'currency'
										)}
										type="string"
										style={{ width: '100%' }}
									/>
									<StyledWrapper.PlaceHolderText>
										{data?.min_value &&
											data?.max_value &&
											`(${data?.min_value}-${data?.max_value})`}
									</StyledWrapper.PlaceHolderText>
								</StyledWrapper.RemoveBorderContainer>
							);
						}}
						value={defaultValue || ''}
					/>

					<FormHelperText error={hasError}>
						{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
					</FormHelperText>
					{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
				</StyledWrapper.FormControlNoBorder>
			)}
		</>
	);
});

MUINumberInput.displayName = 'MUI_NumberInput';
Registry.register('MUI_NumberInput', MUINumberInput);
