import React, { useImperativeHandle } from 'react';
import { DropTarget } from 'react-dnd';
import FormElements from '../form-elements';
import ItemTypes from '../ItemTypes';

const getElement = (item, props) => {
	if (!item) return null;

	let Element;

	if (item.custom) {
		switch (item.key) {
			case 'MUI_Checkbox':
				Element = FormElements['Checkboxes'];
				break;
			case 'MUI_RadioButtons':
				Element = FormElements['RadioButtons'];
				break;
			case 'MUI_StartEndDate':
				Element = FormElements['DatePicker'];
				break;
			default:
				Element = FormElements['TextInput'];
				break;
		}
	} else {
		Element = FormElements[item.element || item.key];
	}

	return (
		<>
			<Element {...props} key={`form_${item.id}`} data={item} />
		</>
	);
};

const getStyle = (backgroundColor) => ({
	border: '1px solid rgba(0,0,0,0.2)',
	minHeight: '2rem',
	minWidth: '7rem',
	width: '100%',
	backgroundColor,
	padding: 0,
	float: 'left'
});

function is_container(item) {
	if (item.itemType !== ItemTypes.CARD) {
		const { data } = item;

		if (data?.is_container) {
			return true;
		} else if (data?.field_name) {
			return data.field_name.indexOf('_col_row') > -1;
		}
	}

	return false;
}

const Dustbin = React.forwardRef(
	(
		{
			draggedItem,
			parent_index,
			canDrop,
			isOver,
			connectDropTarget,
			items,
			col,
			getDataById,
			...rest
		},
		ref
	) => {
		const item = getDataById(items[col]);
		useImperativeHandle(
			ref,
			() => ({
				onDrop: () => {}
			}),
			[]
		);

		const element = getElement(item, rest);
		const sameCard = draggedItem ? draggedItem.index === parent_index : false;

		const validNewDropTarget = !sameCard && isOver && canDrop && !draggedItem.data.is_container;
		const backgroundColor = validNewDropTarget ? '#F7F589' : 'rgba(0, 0, 0, .03)';

		return connectDropTarget(<div style={getStyle(backgroundColor)}>{element}</div>);
	}
);

Dustbin.displayName = 'Dustbin';

export default DropTarget(
	(props) => props.accepts,
	{
		drop(props, monitor, component) {
			if (!component) {
				return;
			}

			const item = monitor.getItem();

			// Do nothing when moving the box inside the same column
			if (props.col === item.col && props.items[props.col] === item.id) return;

			if (!is_container(item)) {
				component.onDrop(item);
				if (item.data && typeof props.setAsChild === 'function') {
					const isNew = !item.data.id && !item.data.field_id;
					const data = isNew ? item.onCreate(item.data) : item.data;

					props.setAsChild(props.data, data, props.col);
				}
			}
		}
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		draggedItem: monitor.getItem() ? monitor.getItem() : null,
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop()
	})
)(Dustbin);
