import { createSlice } from '@reduxjs/toolkit';

const _ = require('lodash');

const initialState = {
	formMetaInfo: {},
	isReadOnly: true,
	form: [],
	errors: [],
	formSchema: [[]],
	dependancies: [],
	copy: {},
	hasChanged: false,
	to_review: [],
	applicationProcess: {},
	saveButtonLoading: false,
	new_form: ''
};

export const formSlice = createSlice({
	name: 'formState',
	initialState,
	reducers: {
		setFormMetaInfo: (state, action) => {
			state.formMetaInfo = { ...action.payload };
		},
		clearMetaInfo: (state) => {
			state.formMetaInfo = {};
		},
		setFormReadOnly: (state, action) => {
			state.isReadOnly = action.payload;
		},
		setFormData: (state, action) => {
			state.form[action.payload[0]] = [...action.payload[1]];
		},
		setFormErrors: (state, action) => {
			state.errors = [...action.payload];
		},
		resetFormData: (state) => {
			state.form = [];
		},
		clearValues: (state) => {
			state.form[1] = state.form?.[1]?.map((e) => {
				return {
					...e,
					value: ''
				};
			});
		},
		setFormSchema: (state, action) => {
			action.payload[1] = _.cloneDeep(action.payload[1]);
			if (action.payload[0] === '') {
				state.formSchema[1] = [...action.payload[1]];
			} else if (action.payload[0] === -1) {
				state.formSchema = action.payload[1];
			} else if (action.payload[0] >= 0) {
				state.formSchema[action.payload[0]] = [...action.payload[1]];
			}
		},
		setDependancies: (state, action) => {
			state.dependancies = action.payload;
		},
		setCopy: (state, action) => {
			state.copy = action.payload ? [action.payload] : undefined;
		},
		clearFormSchema: (state) => {
			state.formSchema = [[]];
		},
		setFormHasChanged: (state, action) => {
			state.hasChanged = action.payload;
		},
		setFormButtonLoadingHasChanged: (state, action) => {
			state.saveButtonLoading = action.payload;
		},
		deleteRelatedDependancies: (state, action) => {
			const formSchema = action.payload?.[0];
			const fieldName = action.payload?.[1].field_name;
			const schema = formSchema?.map((form) => {
				const innerForm = form?.map((e) => {
					if (String(e?.dependency?.field) === String(fieldName)) {
						let formattedElement = _.cloneDeep(e);
						formattedElement.dependency = null;
						return formattedElement;
					} else {
						return e;
					}
				});
				return innerForm;
			});
			state.formSchema = schema;
		},
		setToReviewForms: (state, action) => {
			state.to_review = action.payload;
		},
		setApplicationProcessData: (state, action) => {
			state.applicationProcess = action.payload;
		},
		setNewForm: (state, action) => {
			state.new_form = action.payload;
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	setFormMetaInfo,
	clearMetaInfo,
	setFormReadOnly,
	setFormData,
	setFormErrors,
	setFormSchema,
	setDependancies,
	resetFormData,
	clearValues,
	setCopy,
	clearFormSchema,
	setFormHasChanged,
	setFormButtonLoadingHasChanged,
	deleteRelatedDependancies,
	setToReviewForms,
	setApplicationProcessData,
	setNewForm
} = formSlice.actions;

export default formSlice.reducer;
