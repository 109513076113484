import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML } from '../utils';

import StyledWrapper from './style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { getElementIcon } from '../utils';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const MUIDatePicker = React.forwardRef((props, ref) => {
	const { name, defaultValue, disabled, onChange, required, isFormBuilder } = props;
	const [value, setValue] = useState(defaultValue || null);
	const [hasError, setHasError] = useState(props?.data?.error || false);

	const parsedLabel = useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const startEndDateError = props?.data.startEndDate && props?.data.error?.error !== 'is required';
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	const handleChange = (newValue) => {
		setValue(newValue);
		setHasError(false);

		if (onChange) {
			onChange(newValue);
		}
	};

	useEffect(() => {
		setHasError(props?.data?.error || false);
	}, [props?.data?.error]);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl sx={{ width: '100%' }} variant="outlined">
				{props?.data?.showTimeSelect ? (
					props?.data?.showTimeSelectOnly ? (
						<>
							<Tooltip
								arrow
								placement="top"
								title={isOverflowing ? `${props?.data?.label}` : ''}
								enterTouchDelay={0}
							>
								<StyledWrapper.CustomLabel disabled={disabled}>
									<StyledWrapper.LabelContents ref={labelRef}>
										{isFormBuilder && getElementIcon(props.data?.key)}
										{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									</StyledWrapper.LabelContents>
									{isFormBuilder && (
										<span>
											{' '}
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</span>
									)}
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</StyledWrapper.CustomLabel>
							</Tooltip>
							<StyledWrapper.CustomTimePicker
								name={name}
								value={value}
								disabled={disabled}
								onChange={(newValue) => handleChange(newValue)}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={hasError}
										disabled={disabled}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										style={{ marginTop: '16px' }}
										data-testid={`datepicker-textfield`}
										{...params}
									/>
								)}
							/>
							<StyledWrapper.FormHelperTextContainer>
								<FormHelperText error={hasError}>
									{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}
								</FormHelperText>

								{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
							</StyledWrapper.FormHelperTextContainer>
						</>
					) : (
						<>
							<Tooltip
								arrow
								placement="top"
								title={isOverflowing ? `${props?.data?.label}` : ''}
								enterTouchDelay={0}
							>
								<StyledWrapper.CustomLabel disabled={disabled}>
									<StyledWrapper.LabelContents ref={labelRef}>
										{isFormBuilder && getElementIcon(props.data?.key)}
										{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
										{isFormBuilder && (
											<span>
												{' '}
												{props.data?.unique_identifier
													? `(ff-${props.data?.unique_identifier})`
													: `(tmp-${props.data?.temp_order})`}
											</span>
										)}
									</StyledWrapper.LabelContents>
									{(required || props?.data?.required) && (
										<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
									)}
								</StyledWrapper.CustomLabel>
							</Tooltip>
							<StyledWrapper.CustomDateTimePicker
								name={name}
								minDate={props?.data?.min_date}
								maxDate={props?.data?.max_date}
								disabled={disabled}
								value={value}
								onChange={(newValue) => handleChange(newValue)}
								inputRef={ref}
								renderInput={(params) => (
									<TextField
										error={hasError}
										disabled={disabled}
										InputLabelProps={{ shrink: isFormBuilder || undefined }}
										required={required}
										name={name}
										data-testid={`datepicker-textfield`}
										style={{ width: '100%', marginTop: '16px' }}
										{...params}
									/>
								)}
							/>
							<StyledWrapper.FormHelperTextContainer>
								<FormHelperText error={hasError}>
									{props.data.error?.error ? `${props.data.label} ${props.data.error?.error}` : ''}{' '}
								</FormHelperText>
								{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
							</StyledWrapper.FormHelperTextContainer>
						</>
					)
				) : (
					<>
						<Tooltip
							arrow
							placement="top"
							title={isOverflowing ? `${props?.data?.label}` : ''}
							enterTouchDelay={0}
						>
							<StyledWrapper.CustomLabel disabled={disabled}>
								<StyledWrapper.LabelContents ref={labelRef}>
									{isFormBuilder && getElementIcon(props.data?.key)}
									{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
									{isFormBuilder && (
										<span>
											{' '}
											{props.data?.unique_identifier
												? `(ff-${props.data?.unique_identifier})`
												: `(tmp-${props.data?.temp_order})`}
										</span>
									)}
								</StyledWrapper.LabelContents>
								{(required || props?.data?.required) && (
									<StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>
								)}
							</StyledWrapper.CustomLabel>
						</Tooltip>
						<StyledWrapper.CustomDatePicker
							minDate={props?.data?.min_date || props?.data?.min_value}
							maxDate={props?.data?.max_date || props?.data?.max_value}
							value={value}
							disabled={disabled}
							error={hasError}
							className={isFormBuilder ? 'removeMarginTop noBorder' : ''}
							inputRef={ref}
							onChange={(newValue) => handleChange(newValue)}
							renderInput={(params) => (
								<TextField
									InputLabelProps={{ shrink: isFormBuilder || undefined }}
									required={required}
									disabled={disabled}
									name={name}
									data-testid={`datepicker-textfield`}
									style={{ width: '100%', marginTop: '16px' }}
									{...params}
									error={hasError}
								/>
							)}
						/>
					</>
				)}
			</FormControl>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={hasError}>
					{props.data.error?.error &&
						(startEndDateError
							? `${props.data.error.error}`
							: props?.data.startEndDate
							? `Start/End date is required`
							: `${props.data.label} ${props.data.error.error}`)}
				</FormHelperText>
				{parsedHelperText && <FormHelperText>{parsedHelperText}</FormHelperText>}
			</StyledWrapper.FormHelperTextContainer>
		</LocalizationProvider>
	);
});

MUIDatePicker.displayName = 'MUI_DatePicker';
Registry.register('MUI_DatePicker', MUIDatePicker);
