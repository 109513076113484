import React, { useEffect, useRef, useState } from 'react';

import { Registry } from '../../FormBuilderLibrary';
import { stringToHTML, getElementIcon, useFileUtils } from '../utils';
import StyledWrapper from './style';
import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import DownloadIcon from '@mui/icons-material/Download';

export const MUIFileAttachment = React.forwardRef((props, _ref) => {
	const { defaultValue, disabled, isFormBuilder, data, name } = props;
	const [loading, setLoading] = React.useState(false);
	const { downloadFile } = useFileUtils();

	const parsedLabel = React.useMemo(() => stringToHTML(props.data?.label), [props.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props.data?.help_message),
		[props.data?.help_message]
	);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<>
			<div className="inner-form-group addBorder" name={name}>
				<Tooltip
					arrow
					placement="top"
					title={isOverflowing ? `${props?.data?.label}` : ''}
					enterTouchDelay={0}
				>
					<StyledWrapper.CustomLabel
						value={defaultValue?.length > 0}
						disabled={disabled}
						required={false}
						htmlFor="outlined-adornment-password"
						shrink={true}
						className={'customLabel minus6MarginTop'}
					>
						<StyledWrapper.LabelContents ref={labelRef}>
							{isFormBuilder && getElementIcon(props.data?.key)}
							{parsedLabel?.[0] ? parsedLabel : props.data?.label}
							{isFormBuilder && (
								<span>
									{' '}
									{props.data?.unique_identifier
										? `(ff-${props.data?.unique_identifier})`
										: `(tmp-${props.data?.temp_order})`}
								</span>
							)}
						</StyledWrapper.LabelContents>
					</StyledWrapper.CustomLabel>
				</Tooltip>
				<StyledWrapper.BorderOutline
					className={'customInputOutline'}
					disabled={!data?.src ? true : false}
				>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{data.src && data.src.length > 0 && (
							<>
								{props.data.src.map((file) => {
									let parsedFile = null;
									try {
										if (file != null) {
											if (typeof file === 'string') {
												parsedFile = JSON.parse(file);
											}
											if (typeof file === 'object') {
												parsedFile = file;
											}
										}
									} catch (error) {
										console.error('Error parsing file: ', file);
									}
									if (!parsedFile) {
										return null;
									}
									return (
										<div
											onClick={() => {
												downloadFile(`${parsedFile.uuid}-${parsedFile.filename}`);
											}}
											key={parsedFile.uuid}
											style={{ cursor: 'pointer' }}
										>
											<Tooltip title="Download File" placement="top">
												<IconButton color="error">
													<DownloadIcon color="primary" />
												</IconButton>
											</Tooltip>{' '}
											<span style={{ color: 'rgb(21, 101, 192)' }}>{parsedFile.filename}</span>
										</div>
									);
								})}
							</>
						)}
					</div>
				</StyledWrapper.BorderOutline>
			</div>
			<StyledWrapper.FormHelperTextContainer>
				<FormHelperText error={props.data?.error}>
					{props.data?.error
						? props.data?.error?.error
						: parsedHelperText?.[0]
						? parsedHelperText
						: props.data?.help_message}
				</FormHelperText>
			</StyledWrapper.FormHelperTextContainer>
		</>
	);
});

MUIFileAttachment.displayName = 'MUI_FileAttachment';
Registry.register('MUI_FileAttachment', MUIFileAttachment);
