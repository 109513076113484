import { GPM } from '../gpmAPI';

const exportReportAPI = GPM.injectEndpoints({
	endpoints: (builder) => ({
		exportReviewReport: builder.query({
			query: (props) => ({
				url: `applications/program/${props.programID}/export-review-report/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props.apiToken}`
				},
				responseHandler: async (response) => {
					const blob = await response.blob();
					const contentDisposition = response.headers.get('Content-Disposition');
					return { blob, contentDisposition };
				}
			})
		}),
		exportSubmissionReport: builder.query({
			query: (props) => ({
				url: `applications/program/${props?.programID}/export-submission-report/`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props?.apiToken}`
				},
				responseHandler: async (response) => {
					const blob = await response.blob();
					const contentDisposition = response.headers.get('Content-Disposition');
					return { blob, contentDisposition };
				}
			})
		})
	}),
	overrideExisting: false
});

export const { useLazyExportReviewReportQuery, useLazyExportSubmissionReportQuery } =
	exportReportAPI;
