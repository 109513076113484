import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';

import { SUPPORT_EMAIL } from '../../constants/common';
import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';
import StyledWrapper from './style';

export default function InfoBar() {
	return (
		<StyledWrapper.InfoBarContainer data-testid={'infobar-container'}>
			<StyledWrapper.InnerContainer data-testid={'infobar-inner-container'}>
				{/* <Notifications /> */}
				{/* <Bookmarks /> */}
				<Box sx={{ position: 'relative' }} data-testid={'infobar-help-box'}>
					<Tooltip title={'Contact Euna Support'} arrow placement="top">
						<IconButton
							color="default"
							onClick={() => (window.location.href = `mailto:${SUPPORT_EMAIL}`)}
						>
							<HelpIcon data-testid={'infobar-helpicon'} />
						</IconButton>
					</Tooltip>
				</Box>
			</StyledWrapper.InnerContainer>
			<ProfileDropdown />
		</StyledWrapper.InfoBarContainer>
	);
}
