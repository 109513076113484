import { Component, createRef } from 'react';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';

class CustomElement extends Component {
	constructor(props) {
		super(props);
		this.inputField = createRef();
	}

	render() {
		const { bare } = this.props.data;
		const props = {};
		props.name = this.props.data.field_name;
		props.defaultValue = this.props.defaultValue;

		if (this.props.mutable && this.props.data.forward_ref) {
			props.ref = this.inputField;
		}

		if (this.props.read_only) {
			props.disabled = 'disabled';
		}

		// Return if component is invalid.
		if (!this.props.data.component) return null;
		const Element = this.props.data.component;

		let baseClasses = 'SortableItem rfb-item';

		if (this.props.data.pageBreakBefore) {
			baseClasses += ' alwaysbreak';
		}

		const noPadding = this.props?.data?.key === 'MUI_GroupingStart' ? { padding: '0px' } : {};

		const hideLabel = this.props?.data?.custom_options?.includes('hideLabel');

		const updateStore = () => {
			if (this.props?.data?.key === 'MUI_Calculator' && this.props.updateStore) {
				this.props.updateStore();
			}
		};

		return (
			<div
				key={props?.data?.field_name}
				style={noPadding}
				className={baseClasses}
				data-testid={'SortableItem'}
			>
				<ComponentHeader {...this.props} />
				{bare ? (
					<Element
						data={this.props.data}
						isFormBuilder={this.props.index >= 0 || this.props.accepts?.length > 0}
						updateStore={updateStore}
						{...this.props.data.props}
						{...props}
					/>
				) : (
					<div className="form-group">
						<ComponentLabel
							className={`form-label ${hideLabel ? 'hideLabel' : ''}`}
							{...this.props}
						/>
						<Element
							data={this.props.data}
							isFormBuilder={this.props.index >= 0 || this.props.accepts?.length > 0}
							updateStore={updateStore}
							{...this.props.data.props}
							{...props}
						/>
					</div>
				)}
			</div>
		);
	}
}

CustomElement.propTypes = {};

export default CustomElement;
